import axios from "axios";
import constants from "../../../utils/constants";

const url = constants.BASE_URL + 'questionPaper';



class AssessmentService {
  
    static getTestPaperById(testpaperId) {
        return axios.get(url + '/getQuestionsByTest?testPaperId='+testpaperId);
      }

      static saveinfo=(data)=>{
        console.log("information to be saved is ",data)

        return axios.post(constants.BASE_URL+'studentAnswer/save',data)
      }
  
}

export default AssessmentService;

