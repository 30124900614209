import {DISPATCH_ID} from "../actions/learnModule";
import {SYNOPSIS_ID} from "../actions/learnModule";
import {CURRENTCHAPTERINDEX} from "../actions/learnModule";
import {STUDENT_TEST_CONDUCTING} from "../actions/learnModule";

export default (state = {}, action) => {
    console.log(action)
    switch (action.type) {
        case DISPATCH_ID:
            return { ...state, Id: action.payload  }
            case SYNOPSIS_ID:
            return { ...state, synopsisId: action.payload  }
            case CURRENTCHAPTERINDEX:
                return { ...state, CurentChapterIndexx: action.payload  }
            case STUDENT_TEST_CONDUCTING:
                return { ...state, questionSet: action.payload  }
                case "loginUserDetailskr":
                return { ...state, questionPaperskr: action.payload  }

            
   
        default:
            return state
    }

}
