import axios from "axios";
import constants from "../../../utils/constants";
import loggedInUserDetails from '../../../utils/loggedInUser';

const url = constants.BASE_URL;
const headers = {
    'content-type': 'application/json'
}

class LoginService {

    static login(username, password,loginDevice) {
        let data = {
            userName : username,
            password : password,
            loginDevice: loginDevice
        }
        console.log(" login url ==="+url);
        return axios.post(url + 'auth', data, {headers:headers});
    }
    static logout()
    {
        console.log("logout")
        let data={id:loggedInUserDetails().userId}
        return axios.post(url + 'auth/logout', data, {headers:headers});
    }

}
     

export default LoginService;

