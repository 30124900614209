// import * as crypto from 'crypto-js';
// import constants from './constants';

export class StorageUtil {

  // static encKey = constants.ENCRYPTION_KEY
  // static appKey = constants.APP_KEY

  static getItem(key) {
    let value = undefined
    if (key) {
      let appDataStr = localStorage.getItem(key)
      let appData = appDataStr ? JSON.parse(appDataStr) : {}
      value = appData
    }
    return value
  }

  static setItem(key, value) {
    let result = false
    
    if (key && value) {
      value = JSON.stringify(value)
      // value = this.encrypt(value)
      localStorage.setItem(key, value)
      result = true
    }
    return result
  }

  static remove(key) {
    localStorage.removeItem(key) 
 }

  // static deleteStorageData() {
  //   localStorage.removeItem(this.appKey)
  // }

  // static deleteAll() {
  //   localStorage.clear()
  // }

  // static encrypt(value) {
  //   return crypto.AES.encrypt(value, this.encKey)
  // }

  // static decrypt(value) {
  //   return crypto.AES.decrypt(value, this.encKey).toString(crypto.enc.Utf8)
  // }

}
