// Common Reducers should only be written here module specific reducers should be written in sepcific modules

//import { StorageService } from "../services/StorageService";

// Initial State
const initialState = {
    loggedIn: false,
    profile: {},
    deviceId:"",
    deviceToken:"",
    fcmToken:"",
};
// Reducers (Modifies The State And Returns A New State)
const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'LOGIN':
            return { ...state, 
                loggedIn : localStorage.getItem("isLoggedIn"),
             }
        case 'LOGOUT':
            return { ...state, 
                loggedIn:localStorage.getItem("isLoggedIn"),
             }
        case 'SAVE_PROFILE': {
            return {
                // State
                ...state,
                // Redux Store
                profile: action.profile
            }
        }
        case 'SAVE_DEVICE_INFO': {
            return {
                // State
                ...state,
                deviceId: action.deviceInfo.deviceId,
                deviceToken: action.deviceInfo.deviceToken,
            }
        }
        case 'SAVE_FCM_INFO': {
            return {
                // State
                ...state,
                // Redux Store
                fcmToken: action.fcmInfo.fcmToken,
            }
        }

        // Default
        default: {
            return state;
        }
    }
};
// Exports
export default authReducer;