import { combineReducers } from "redux";
import storage from 'redux-persist/lib/storage'
import auth_reducer from "../../storage/reducers";
import { persistReducer } from "redux-persist";
import learnModule_reducer from "./learnModuleReducer";

const rootReducer = combineReducers({
    authReducer: auth_reducer,
    learnModule: learnModule_reducer,
    
})
// Middleware: Redux Persist Config
const persistConfig = {
    // Root
    key: 'root',
    // Storage Method defaults to localstorage
    storage,
    // Whitelist (Save Specific Reducers)
    whitelist: [
        'authReducer','learnModule',
    ],
    timeout: null,
    // Blacklist (Don't Save Specific Reducers)
    // blacklist: [
    // ],
};
export default persistReducer(persistConfig, rootReducer);