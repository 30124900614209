import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Loadable from 'react-loadable';
import './App.scss';
import ExamPage from '../src/views/assessment/examPage';
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./storage/store";
import { persistStore } from "redux-persist";
import loggedInUserDetails from "./utils/loggedInUser";
import { StorageUtil } from './utils/StorageUtil';
import LoginService from './views/Pages/services/loginService';


const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

// Containers
const DefaultLayout = Loadable({
  loader: () => import('./containers/DefaultLayout'),
  loading
});

// Pages
const Login = Loadable({
  loader: () => import('./views/Pages/Login'),
  loading
});

const Register = Loadable({
  loader: () => import('./views/Pages/Register'),
  loading
});

const Page404 = Loadable({
  loader: () => import('./views/errorPages/page404'),
  loading
});

const Page500 = Loadable({
  loader: () => import('./views/errorPages/page500'),
  loading
});

class App extends Component {

 componentDidMount()
 {
  var d = new Date();
  var curr_date = d.getDate();
  var curr_month = d.getMonth();
if(loggedInUserDetails() && loggedInUserDetails().loginTime!=curr_date)
{
  LoginService.logout()
  StorageUtil.remove("loginUserDetails");
}
else{
  if(loggedInUserDetails()  && loggedInUserDetails().loginMonth!=curr_month)
  {
    LoginService.logout()
    StorageUtil.remove("loginUserDetails");
 }
}
 }

  componentWillMount() {
    persistStore(store, {}, () => {
    this.setState({rehydrated: true})
    })
    }
    
  render() {
    return (
      <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
      <Router>
        <Switch>
         <Route exact path="/" name="Login Page" component={Login} />
          <Route exact path='/assessment/examPage' name="Exam Page" component={ExamPage} />
          <Route exact path="/login" name="Login Page" component={Login} />
          <Route exact path="/register" name="Register Page" component={Register} />
          <Route exact path="/404" name="Page 404" component={Page404} />
          <Route exact path="/500" name="Page 500" component={Page500} />
          <Route path="/" name="Home" component={DefaultLayout} />
        </Switch>
      </Router>
      </PersistGate>
      </Provider>
    );
  }
}

export default App;
