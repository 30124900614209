import React, { Component } from 'react';
import { Button } from 'reactstrap';
import AssessmentService from './service/assessmentService';
import loggedInUserDetails from '../../utils/loggedInUser';
import { StorageUtil } from '../../utils/StorageUtil';
import Modal from 'react-modal';
class ExamPage extends Component {

    constructor(props) {
        super(props);
        this.state = {

            hour: 3,
            minutes: 0,
            seconds: 0,
            total: loggedInUserDetails().AttempedQuestionPaper ? loggedInUserDetails().AttempedQuestionPaper.length : loggedInUserDetails().questionPaper.length,
            nr: 0,
            isNext: true,
            isPrevious: false,
            score: 0,
            right: 0,
            wrong: 0,
            result: [],
            data: loggedInUserDetails().AttempedQuestionPaper ? loggedInUserDetails().AttempedQuestionPaper : loggedInUserDetails().questionPaper,
            question: '',
            answers: '',
            id: '',
            ischecked: '',
            correct: '',
            questionAnswerRequestList: loggedInUserDetails().questionAnswerRequestList ? loggedInUserDetails().questionAnswerRequestList : [],
            show: '',
            isPause: false

        }
        this.nextQuestion = this.nextQuestion.bind(this);
        this.previousQuestion = this.previousQuestion.bind(this);
        this.answerCliked = this.answerCliked.bind(this);
        this.jumpToQuestion = this.jumpToQuestion.bind(this);
        this.submitTest = this.submitTest.bind(this);
        this.pauseTest = this.pauseTest.bind(this);
        this.resumeTest = this.resumeTest.bind(this);
    }
    componentWillMount() {
        console.log("this.props.location.data", this.props.location.data)

        if (this.state.data.length !== 0) {
            let { nr } = this.state;
            this.pushData(nr);
        }
        this.setTimer()
    }
    setTimer() {
        this.myInterval = setInterval(() => {
            console.log("i am in interval")
            const { seconds, minutes, hour } = this.state

            if (seconds > 0) {
                this.setState(({ seconds }) => ({
                    seconds: seconds - 1,
                }))
            }
            if (seconds === 0) {
                if (minutes !== 0)
                    this.setState(({ minutes }) => ({
                        minutes: minutes - 1,
                        seconds: 59
                    }))
            }
            if (minutes === 0) {
                if (hour === 0) {
                    if (seconds === 0) {
                        clearInterval(this.myInterval)
                    }
                } else {
                    this.setState(({ hour }) => ({
                        hour: hour - 1,
                        minutes: 59
                    }))
                }
            }

        }, 1000)
    }
    componentWillUnmount() {

        clearInterval(this.myInterval)
    }
    pauseTest() {
        clearInterval(this.myInterval)
        this.setState({ isPause: true })
        console.log("hr", this.state.hour)
        console.log("minut,", this.state.minutes)
        console.log("second", this.state.seconds)
    }
    resumeTest() {
        console.log("i am in resume")
        this.setTimer()
        this.setState({ isPause: false })
    }

    pushData(nr) {
        console.log("inside pushdata")
        let { total, data } = this.state;
        console.log("data inside push data is", data)
        if (nr > 0) {
            this.setState({ isPrevious: true })
        }
        this.setState({
            question: data[nr].question,
            answers: data[nr].answers,
            id: data[nr].id,
            ischecked: data[nr].ischecked,
            correct: data[nr].correct,
            nr: this.state.nr + 1

        });

    }
    jumpToQuestion = (nr) => {
        let { total, data } = this.state;

        this.setState({
            question: data[nr - 1].question,
            answers: data[nr - 1].answers,
            id: data[nr - 1].id,
            ischecked: data[nr - 1].ischecked,
            correct: data[nr - 1].correct,
            nr: nr,
        });
        if (nr === 1) {
            this.setState({ isPrevious: false })
        }
        else {
            this.setState({ isPrevious: true })
        }
    }

    nextQuestion() {
        let { nr, total } = this.state;


        if (nr === total) {
            this.setState({ isNext: false })
        } else {

            this.pushData(nr);
        }

    }
    popData(nr) {
        let { total, data } = this.state;
        console.log("nr", nr)
        this.setState({
            question: data[nr - 1].question,
            answers: data[nr - 1].answers,
            id: data[nr - 1].id,
            ischecked: data[nr - 1].ischecked,
            correct: data[nr].correct,

        });
    }
    previousQuestion() {
        let { nr, total } = this.state;

        if (nr === 2) {
            this.setState({ isPrevious: false })
        }
        this.setState({
            isNext: true,
            nr: this.state.nr - 1
        }, () => { this.popData(this.state.nr) }
        );
    }
    submitinfo(questionInfo) {
        var arrayList = []
        console.log("this.state.data", this.state.data)
        for (var i = 0; i < this.state.data.length; i++) {
            var obj = {};
            obj.questionPaperId = this.state.data[i].id;
            obj.answer = null;
            arrayList.push(obj);
        }
        for (var i = 0; i < questionInfo.length; i++) {
            for (var j = 0; j < arrayList.length; j++) {
                if (questionInfo[i].questionPaperId === arrayList[j].questionPaperId) {
                    arrayList[j].answer = questionInfo[i].answer;
                }
            }
        }
        console.log("arrayList", arrayList);
        var dataSet = "";
        var obj = {};
        obj.studentId = loggedInUserDetails().userId;
        obj.testPaperId = loggedInUserDetails().testpaperId;
        obj.questionAnswerRequestList = arrayList;
        dataSet = obj;
        console.log("dataSet", dataSet)

        AssessmentService.saveinfo(dataSet).then((res) => {
            console.log("res is ", res)
            alert("Your exam is submitted successfully");
            this.props.history.push({
                pathname: '/assessment/studentResults',
                data: res.data.data
            })

        }).catch((error) => {
            console.log("error", error)

        });

    }
    answerCliked = (qId, answerId, correct, option) => {
        var j = "";
        var position = "";
        let { total, data } = this.state;
        var obj = {};
        obj.questionPaperId = qId;
        obj.answer = option;
        for (var i = 0; i < data.length; i++) {
            if (data[i].id === qId) {
                data[i].ischecked = true;
                for (var j = 0; j < 4; j++) {
                    if (data[i].answers[j].answerId === answerId) {
                        data[i].answers[j].ischecked = true;
                    }
                    else {
                        data[i].answers[j].ischecked = false;
                    }
                }
            }
        }
        var loginUserDetails = loggedInUserDetails();
        loginUserDetails.AttempedQuestionPaper = data;
        loginUserDetails.AttempedQuestionPaper = data;
        StorageUtil.setItem("loginUserDetails", loginUserDetails);

        if (this.state.questionAnswerRequestList.length === 0) {
            this.state.questionAnswerRequestList.push(obj)
        }
        else {
            for (var i = 0; i < this.state.questionAnswerRequestList.length; i++) {
                if (this.state.questionAnswerRequestList[i].questionPaperId === qId) {

                    j = true;
                    position = i;
                    break;
                }
                else {
                    j = false;
                }
            }
            if (j === true) {
                console.log("inside flag true")
                this.state.questionAnswerRequestList.splice(position, 1, obj)
            }
            else {
                this.state.questionAnswerRequestList.push(obj);
            }
        }
        var loginUserDetails = loggedInUserDetails();
        loginUserDetails.AttempedQuestionPaper = data;
        loginUserDetails.questionAnswerRequestList = this.state.questionAnswerRequestList;
        StorageUtil.setItem("loginUserDetails", loginUserDetails);

    }
    submitTest() {
        this.submitinfo(this.state.questionAnswerRequestList)
    }
    timeOver() {
        const { seconds, minutes, hour } = this.state
        if (seconds === 0 && minutes === 0 && hour === 0) {
            this.submitinfo(this.state.questionAnswerRequestList)
        }
    }

    render() {
        if (this.state.data.length === 0) {
            return (
                <div className="row p-6">
                    <div className="col-lg-6 col-sm-6 offset-3"  >
                        <div className="card shadow_box_wrapper exam-grid" >
                            <div className="shadow-lg p-1 mb-0 bg-white rounded">
                                <div className="card-header">
                                    <h3 className="link__style_with_arrow text col text-center ">Exam</h3>
                                </div>
                                <div className="col-lg-12 col-sm-12" >
                                    <div className="row exam-wrapper">
                                        <div className="col-lg-8 col-sm-10 " >
                                            <h5 className="common__text_exam col text-center">Currently there is no question available for this exam</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        else if (this.state.isPause) {
            return (
                <div className="row p-6">
                    <div className="col-lg-6 col-sm-6 offset-3"  >
                        <div className="card shadow_box_wrapper exam-grid" >
                            <div className="shadow-sm p-1 mb-0 bg-white rounded">
                                <div className="card-header">
                                    <h6 className="link__style_with_arrow text col text-center ">Please click resume button to resume your examination</h6>
                                </div>
                                <div className="col text-center mt-3">
                                    <Button className="btn btn-warning mx-2 " onClick={this.resumeTest}>Resume</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        else {
            let { nr, total, question, answers, hour, minutes, seconds, id, correct, ischecked } = this.state;
            return (
                    <div className="p-4" >
                        <div className="row">
                            <div className="col-sm-9 col-md-9 col-lg-9 col-xl-9">

                                <div className="container" >
                                    <div className="row">
                                        <div className="col-sm-8 col-md-8 col-lg-8 col-xl-8">
                                            <div className="row">
                                                <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3">
                                                    <h4 >Question:{nr}</h4>
                                                </div>
                                                <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3">
                                                    <h4 style={{ color: "green" }}>4 marks</h4>
                                                </div>
                                                <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3">
                                                    <h4 style={{ color: "red" }}>-1 mark</h4>
                                                </div>
                                                <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3 ">
                                                    {minutes === 0 && seconds === 0
                                                        ? <h4>{this.timeOver()}</h4>
                                                        : <h4>{hour}:{minutes < 10 ? `0${minutes}` : minutes}:{seconds < 10 ? `0${seconds}` : seconds}</h4>
                                                    }
                                                </div>

                                            </div>
                                        </div>
                                        <div className="col text-right mt-1  ">
                                            <Button className="btn btn-info mx-2" onClick={this.pauseTest}>Pause</Button>
                                            <Button className="btn btn-danger mx-2" onClick={this.submitTest}>Submit</Button>
                                        </div>

                                    </div>
                                    <hr className="m-0 headerLine"></hr>

                                    <div style={{ marginTop: "10px" }}>
                                        <h4 ><span><div className="answeroption" dangerouslySetInnerHTML={{ __html: question }} />
                                        </span></h4>
                                    </div>

                                    <div className="col-lg-10 col-sm-10 answerPannel"
                                        style={answers[0].ischecked ? { backgroundColor: 'lightblue', borderRadius: "10px" } : { backgroundColor: 'transparent' }}
                                        onClick={e => { this.answerCliked(id, answers[0].answerId, correct, "a") }}>
                                        <table className="answeroption" dangerouslySetInnerHTML={{ __html: answers[0].answerText }} />
                                    </div>

                                    <div className="col-lg-10 col-sm-10 answerPannel"
                                        style={answers[1].ischecked ? { backgroundColor: 'lightblue', borderRadius: "10px" } : { backgroundColor: 'transparent' }}
                                        onClick={e => { this.answerCliked(id, answers[1].answerId, correct, "b") }}>
                                        <table className="answeroption" dangerouslySetInnerHTML={{ __html: answers[1].answerText }} />
                                    </div>


                                    <div className="col-lg-10 col-sm-10 answerPannel"
                                        style={answers[2].ischecked ? { backgroundColor: 'lightblue', borderRadius: "10px" } : { backgroundColor: 'transparent' }}
                                        onClick={e => { this.answerCliked(id, answers[2].answerId, correct, "c") }}>
                                        <table className="answeroption" dangerouslySetInnerHTML={{ __html: answers[2].answerText }} />
                                    </div>


                                    <div className="col-lg-10 col-sm-10 answerPannel"
                                        style={answers[3].ischecked ? { backgroundColor: 'lightblue', borderRadius: "10px" } : { backgroundColor: 'transparent' }}
                                        onClick={e => { this.answerCliked(id, answers[3].answerId, correct, "d") }}>
                                        <table className="answeroption" dangerouslySetInnerHTML={{ __html: answers[3].answerText }} />
                                    </div>
                                    <div className="row">
                                        <div className="col text-center mt-3">
                                            <Button className={this.state.isPrevious ? "btn btn-warning mx-2" : "d-none"} onClick={this.previousQuestion}>Previous Question </Button>
                                            <Button className={this.state.isNext ? "btn btn-info mx-2" : "d-none"} onClick={this.nextQuestion} >Next Question</Button>
                                        </div>
                                    </div>
                                </div>
                            </div >
                            <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3" >
                                <div className="mt-1 response" >
                                    <div className="row rowContent"  >
                                        <h6 className="p-2 attempted">Attempted</h6>
                                        <h6 className="p-2 notAttempted">Not Attempted</h6>
                                    </div>
                                    <div className="row rowContent" >

                                        {this.state.data.map((data, index) => (
                                            <div>
                                                <div>
                                                    <Button className={data.ischecked ? "btn btn-success mx-2 row mt-3" : " btn btn-danger mx-2 row mt-3"} onClick={e => { this.jumpToQuestion(index + 1) }} >{index + 1}</Button>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div >
            )
        }
    }
}
export default ExamPage;