//import { wrapperAPI } from "../ApiService";
export const DISPATCH_ID= 'dispatchId';
export const SYNOPSIS_ID= 'dispatchSynopsisIdId';
export const CURRENTCHAPTERINDEX= 'dispatchCurentChapterIndexx';
export const STUDENT_TEST_CONDUCTING= 'apiName';


export const dispatchId = (request, forceFetch = false) =>  dispatch => {
    //const res = await wrapperAPI(STUDENT_TEST_CONDUCTING, request, forceFetch)
    //const res = "sas"
    dispatch({ type: DISPATCH_ID , payload: request })
}
export const dispatchSynopsisId = (request) =>  dispatch => {
    dispatch({ type: SYNOPSIS_ID , payload: request })
}
export const dispatchCurentChapterIndexx = (request) =>  dispatch => {
    dispatch({ type: CURRENTCHAPTERINDEX , payload: request })
}
export const studentTestConduct = (request, forceFetch = false) =>  dispatch => {
    //const res = await wrapperAPI(STUDENT_TEST_CONDUCTING, request, forceFetch)
    //const res = "sas"
    dispatch({ type: "loginUserDetailskr" , payload: request })
}
