// import decryptDetails from "./decryptDetails";
import { StorageUtil } from "./StorageUtil";

// get Logged in user details
const loggedInUserDetails = function () {
    var user = StorageUtil.getItem("loginUserDetails");
   
    if (!user) {
        user = {};
    }
    return user
}

export default loggedInUserDetails;
