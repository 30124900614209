const constants = {
    ACCESSS_TOKEN: "3b7caf62-2838-428e-9018-872bad5b218d",
    APP_KEY: 'APP_KEY',
    //BASE_URL: "http://13.235.17.19/app/api/v1/",
    // BASE_URL: "http://192.168.1.23:8094/api/v1/",
    BASE_URL: "http://app.upmyranks.com/app/api/v1/",
    //BASE_URL: "http://13.234.226.181/eduInstitute/api/v1/",
    ROLEID: {
        SUPERADMIN: '01476e15-dfae-4068-8beb-e5bce310ee3e',
        ADMIN: '02476e15-dfae-4068-8beb-e5bce310ee3e',
        COACH: "03476e15-dfae-4068-8beb-e5bce310ee3e",
        STUDENT: '04476e15-dfae-4068-8beb-e5bce310ee3e',
        BATCHADMIN: '05476e15-dfae-4068-8beb-e5bce310ee3e',
        CLERK: '06476e15-dfae-4068-8beb-e5bce310ee3e'
    },
    ROLES: {
        SUPER_ADMIN: "SUPERADMIN",
        COACHING_ADMIN: "ADMIN",
        BRANCH_ADMIN: "BRANCHADMIN",
        COACH: "COACH",
        STUDENT: "STUDENT",
        CLERK: "CLERK",
        ALL: ["SUPERADMIN", "COACH", "ADMIN", "STUDENT", "CLERK","BRANCHADMIN"]
    }
}
export default constants;
