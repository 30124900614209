// Imports: Dependencies
import { createStore, applyMiddleware, combineReducers } from 'redux';
import { createLogger } from 'redux-logger';
import { persistStore, persistReducer } from 'redux-persist';
import reducers from '../services/reducers/root_reducer';
import thunk from 'redux-thunk';

// console.log('reducers', typeof (reducers))
console.log('initial state...', getInitialState())
// Redux: Store
const store = createStore(
    reducers,
    getInitialState(),
    applyMiddleware(
        thunk,
        createLogger(),
    ),
);
// Middleware: Redux Persist Persister
const persistor = persistStore(store);

function getInitialState() {
    console.log("window.INITIAL_STATE", typeof (window) !== 'undefined' && window.INITIAL_STATE)
    return typeof (window) !== 'undefined' && window.INITIAL_STATE ? window.INITIAL_STATE : {}
}

export { store, persistor };